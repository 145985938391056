.app__chef-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5rem;
  }
  
  .app__chef-content_quote {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }
  
  .app__chef-content_quote img {
    width: 47px;
    height: 40px;
    margin: 0 1rem 1rem 0;
  }
  
  .app__chef-sign {
    width: 100%;
    margin-top: 3rem;
  }
  
  .app__chef-sign p:first-child {
    font-family: var(--font-base);
    font-weight: 400;
    font-size: 32px;
    line-height: 41.6px;
    letter-spacing: 0.04em;
    text-transform: capitalize;
    color: var(--color-golden);
  }
  
  .app__chef-sign img {
    width: 250px;
    margin-top: 3rem;
  }
  
  @media screen and (min-width: 2000px) {
    .app__chef-sign img {
      width: 370px;
    }
  }
  
  @media screen and (max-width: 450px) {
    .app__chef-sign img {
      width: 80%;
    }
  }
  
  /* smartphones, iPhone, portrait 480x320 phones */
  @media (min-width: 320px) {
    .app__chef-sign p:first-child {
      font-size: 28px;
    }
  }
  
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide */
  @media (min-width: 481px) {
    .app__chef-sign p:first-child {
      font-size: 30px;
    }
  }
  
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width: 641px) {
    .app__chef-sign p:first-child {
      font-size: 32px;
    }
  }
  
  /* tablet, landscape iPad, lo-res laptops and desktops */
  @media (min-width: 961px) {
    .app__chef-sign p:first-child {
      font-size: 34px;
    }
  }
  
  /* big landscape tablets, laptops, and desktops */
  @media (min-width: 1025px) {
    .app__chef-sign p:first-child {
      font-size: 36px;
    }
  }
  
  /* hi-res laptops and desktops */
  @media (min-width: 1281px) {
    .app__chef-sign p:first-child {
      font-size: 38px;
    }
  }
  