.impressum-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 900px;
  margin: 2rem auto;
  padding: 1rem;
  line-height: 1.5;
  background: var(--color-black);
  color: white;
  text-align: center;
}

.impressum-page h1,
.impressum-page h2,
.impressum-page h3 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: 'Cormorant Garamond', serif;
}

.impressum-page p {
  margin-bottom: 1rem;
  font-family: 'Open Sans', sans-serif;
}

.impressum-page h2 {
  word-wrap: break-word;
  word-break: break-word;
}