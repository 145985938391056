.app__wrapper_img{
  width: auto !important;
  height: 50% !important; 
}

/* Basisstil für die Schriftgröße der Adresse */
.p__opensans {
  font-size: 21px;
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) {
  .p__opensans {
    font-size: 14px;
  }
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide */
@media (min-width: 481px) {
  .p__opensans {
    font-size: 15px;
  }
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
  .p__opensans {
    font-size: 18px;
  }
}

/* tablet, landscape iPad, lo-res laptops and desktops */
@media (min-width: 961px) {
  .p__opensans {
    font-size: 20px;
  }
}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  .p__opensans {
    font-size: 25px;
  }
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  .p__opensans {
    font-size: 30px;
  }
}
