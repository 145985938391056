.app__principles_awards {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;

    margin-top: 3rem;
}

.app__principles_awards-card {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    min-width: 230px;

    margin: 1rem;
    position: relative;
}

.app__principles_awards-card_bullet {
    position: absolute;
    left: -1.5rem;
    top: 1.5rem;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    background-color: #DCCA87;
}

@media screen and (min-width: 1900px) {
    .app__principles_awards-card {
        min-width: 390px;
    }
}

.app__principles_awards-card img {
    width: 50px;
    height: 50px;
}

.app__principles_awards-card_content {
    display: flex;
    flex-direction: column;
    margin-left: 1rem;
}

.p__cormorant {
    position: relative;
    margin-top: 0;
}

@media screen and (max-width: 450px) {
    .app__principles_awards-card {
        min-width: 100%;
        margin: 1rem 0;
    }
}
