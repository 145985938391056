.fadeIn {
    opacity: 0;
    animation: fadeIn 2s ease-in-out forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .fade-enter {
    opacity: 0;
  }
  
  .fade-enter-active {
    opacity: 1;
    transition: opacity 2s ease-in-out;
  }
  
  .fade-exit {
    opacity: 1;
  }
  
  .fade-exit-active {
    opacity: 0;
    transition: opacity 2s ease-in-out;
  }
  
  
.app__aboutus {
    position: relative;
}

.app__aboutus-overlay {
    position: absolute;
    inset: 0;
}

.app__aboutus-content {
    z-index: 2;
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    opacity: 1;
}

.app__aboutus-content_about {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
}

.app__aboutus-content_cocktails {
    margin: 2rem 4rem;
}

.app__aboutus-content_cocktails img {
    width: 50%;
    height: auto;
}

.app__aboutus-content_history {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: left;
}

@media screen and (min-width: 2000px) {
    .app__aboutus-content_knife img {
        height: 1110px;
    }

    .app__aboutus-content_about p,
    .app__aboutus-content_history p {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 900px) {
    .app__aboutus-content {
        flex-direction: column;
    }

    .app__aboutus-content_knife {
        margin: 4rem 0;
    }
}

@media screen and (max-width: 650px) {
    .app__aboutus-overlay img {
        width: 80%;
        height: 320px;
    }
}