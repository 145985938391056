.app__specialMenu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: black;
    padding: 50px 0;
  }
  
  .app__specialMenu-inner {
    position: relative;
    width: 100%;
  }
  
.app__specialMenu-title {
    margin-bottom: 2rem;
    text-align: center;
}

.app__specialMenu-menu {
    width: 100%;
    margin: 2rem 0;

    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: row;

    border-bottom: 1px solid white;
    padding-bottom: 2rem;
}

.app__specialMenu-menu_heading {
    font-family: var(--font-base);
    font-weight: 600;
    font-size: 45px;
    line-height: 58.5px;
    letter-spacing: 0.04em;
    color: var(--color-white);
}

.app__specialMenu-menu_burgers,
.app__specialMenu-menu_cocktails {
    flex: 1;
    width: 100%;
    flex-direction: column;
}

.app__specialMenu_menu_items {
    display: flex;
    flex-direction: column;

    margin: 2rem 0;
    width: 80%;
    height: auto;
}

.app__specialMenu-footnote {
    margin-top: 2rem;
    text-align: center;
    font-size: 0.8rem;
    color: var(--color-white);
  }

  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .imageContainer img {
    width: 50%;
    height: auto;
}

  /* smartphones, iPhone, portrait 480x320 phones */
  @media (min-width: 320px) {
    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .app__specialMenu-menu {
        width: 100%;
        max-width: 100%;
      }
    
      .app__specialMenu_menu_items {
        width: 100%;
      }
  }
  
  /* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide */
  @media (min-width: 481px) {
    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .app__specialMenu-menu {
        width: 100%;
        max-width: 100%;
      }
    
      .app__specialMenu_menu_items {
        width: 100%;
      }
  }
  
  /* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
  @media (min-width: 641px) {
    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }

    .app__specialMenu-menu {
        width: 100%;
        max-width: 100%;
      }
    
      .app__specialMenu_menu_items {
        width: 100%;
      }
  }
  
  /* tablet, landscape iPad, lo-res laptops and desktops */
  @media (min-width: 961px) {
    .app__specialMenu-menu_heading {
        font-size: 35px;
        line-height: 48.5px;
    }
  }
  
  /* big landscape tablets, laptops, and desktops */
  @media (min-width: 1025px) {
    .app__specialMenu-menu {
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
  }
  
  /* hi-res laptops and desktops */
  @media (min-width: 1281px) {
    .app__specialMenu {
        padding: 7rem 0;
    }

    .app__specialMenu-inner {
        position: relative;
        padding-left: 15%;
        padding-right: 15%;
        width: 100%;
      }
  }
  