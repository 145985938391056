.subheading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 1rem;
}

.spatula__img {
  width: 50px;
  height: auto;
  margin-top: -20px;
}

/* smartphones, iPhone, portrait 480x320 phones */
@media (min-width: 320px) {
  .spatula__img {
    margin-top: -20px;
  }
}

/* portrait e-readers (Nook/Kindle), smaller tablets @ 600 or @ 640 wide */
@media (min-width: 481px) {
  .spatula__img {
    margin-top: -20px;
  }
}

/* portrait tablets, portrait iPad, landscape e-readers, landscape 800x480 or 854x480 phones */
@media (min-width: 641px) {
  .spatula__img {
    margin-top: -20px;
  }
}

/* tablet, landscape iPad, lo-res laptops ands desktops */
@media (min-width: 961px) {
  .spatula__img {
    margin-top: -20px;
  }
}

/* big landscape tablets, laptops, and desktops */
@media (min-width: 1025px) {
  .spatula__img {
    margin-top: -20px;
  }
}

/* hi-res laptops and desktops */
@media (min-width: 1281px) {
  .spatula__img {
    margin-top: -20px;
  }
}